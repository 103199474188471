<template>
	<WwModal
		:modal-id="DELETE_REVIEW_MODAL"
		:header-offset="235"
		ok-title="Delete"
		ok-color="red"
		ok-text-color="text-white"
		max-width="w-full md:max-w-lg"
		modal-border-radius="rounded-t-2xl md:rounded-3xl"
		:transition-direction="!isMobile ? 'down' : 'bottom'"
		@ok="handleSubmit"
	>
		<template #body>
			<div class="grid content-center justify-center grid-cols-1 gap-6 pt-4 pb-8 overflow-y-hidden text-center xs:px-6 sm:px-10">
				<div class="text-2xl font-bold text-black">
					Are you sure you want to delete this review?
				</div>
			</div>
		</template>
		<template #footer="{ handleHideButtonClick }">
			<div class="grid grid-cols-1 gap-4 pb-1 uppercase xs:text-sm">
				<WwSubmitButton
					class="mx-auto"
					:disabled="false"
					:is-loading="isLoading"
					button-text="DELETE REVIEW"
					button-height="h-11 md:h-13"
					button-width="w-48"
					border-radius="rounded-md"
					@submit="handleSubmit"
				/>
				<button
					class="px-4 py-1 mx-auto font-bold text-center text-green-500 transition-all duration-100 rounded-md hover:text-purple-500 ease xl:hover:bg-gray-200"
					@click="handleHideButtonClick"
				>
					CANCEL
				</button>
			</div>
		</template>
	</Wwmodal>
</template>

<script defer>
import { mapGetters, mapMutations } from 'vuex'

import WwSubmitButton from '@/components/UI/buttons/WwSubmitButton.vue'
import WwModal from '@/components/UI/WwModal.vue'
import { DELETE_REVIEW_MODAL } from '@/constants/modal/names.js'

export default {
	components: {
		WwModal,
		WwSubmitButton
	},
	props: {
		listingName: {
			type: String,
			required: true
		},
		reviewId: {
			type: Number,
			required: true
		},
		listingId: {
			type: Number,
			required: true
		}
	},
	emits: [ 'delete-review' ],
	data() {
		return {
			DELETE_REVIEW_MODAL
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ])
	},
	methods: {
		...mapMutations('modal', [ 'closeModal' ]),
		handleClose(event) {
			event.stopPropagation()
			this.closeModal()
		},
		handleSubmit() {
			this.$emit('delete-review', this.reviewId, this.listingId)
			this.closeModal()
		}
	}
}
</script>
